import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Table, Space, Radio, Tag } from 'antd';
import { find, remove, differenceBy } from 'lodash'
import { API } from 'aws-amplify';
import * as custom_queries from "../../../../Custom_queries/queries";
import './Ubicaciones.css'

const { Search } = Input;

const Ubicaciones = (
  {
    ubicaciones,
    setUbicaciones,
  }
) => {
  const [data, setData] = useState()
  const [nextPage, setNextPage] = useState()
  const [searchValue, setSearchValue] = useState()
  const [showLoadingData, setShowLoadingData] = useState(false)
  const [columns, setColumns] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [locations, setLocations] = useState([])
  const [banderaTags, setBanderaTags] = useState(false)

  const fetchFilter = async (filter = null) => {
    try {
      const response = await API.graphql({
        query: `query MyQuery {
          getFiltersRedshift(input: { type_location: 1, query: "getLocation", name_filter: "${filter}" }) {
            key
            description_filter
            type_filter
            value_filter
            id_statement
          }
        }`
      });
  
      const result = response.data.getFiltersRedshift.sort((a, b) => a.value_filter.localeCompare(b.value_filter));
  
      if (result[0].id_statement !== null) {
        await getValues({ idStatement: result[0].id_statement });
      } else {
        const newData = result.map(item => {
          const { id_statement, ...rest } = item;
          return rest;
        });
  
        setData(newData.length > 0 && newData[0].value_filter !== "Sin Datos" ? newData : []);
        setShowLoadingData(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };

  useEffect(() => {
    setShowLoadingData(true)
    fetchFilter(searchValue)
  }, [searchValue])

  const getValues = async ({ idStatement }) => {
    const startTime = Date.now();
    let response = [];
    while (response.length === 0) {
      const result = await API.graphql({
        query: `query MyQuery {
          getData(input: {id_statement: "${idStatement}", query: "getLocation"}){
            key
            description_filter
            type_filter
            value_filter
          }
        }`
      });
      response = result.data.getData;
      if (response.length === 0) {
        await new Promise(resolve => setTimeout(resolve, 5000)); // Esperar 5 segundos antes de hacer la siguiente petición
      }
    }
    setData(response.length > 0 && response[0].value_filter !== "Sin Datos" ? response : [])
    setShowLoadingData(false)
  };

  useEffect(() => {
    if (locations.length > 0) {
      setSelectedRowKeys(locations.map(item => item.key))
      setUbicaciones(locations)
    }
    //Por bug cuando hay un solo tag
    if (locations.length == 0) {
      setSelectedRowKeys([])
      if (banderaTags) {
        setBanderaTags(false)
        setUbicaciones([])
      }
    }
  }, [locations])

  useEffect(()=>{
    if (data && ubicaciones.length == 0){
      const updatedData = data.map(item => ({
        ...item,
        description_filter: 'estado_home',
      }));
      setData(updatedData);
    }
  },[ubicaciones])

  useEffect(() => {
    const diff = differenceBy(ubicaciones, locations, 'key')
    if (diff.length > 0) {
      setSelectedRowKeys(ubicaciones.map(item => item.key))
      setLocations(ubicaciones)
    }

    if (ubicaciones.length === 0) {
      setSelectedRowKeys([])
      setBanderaTags(false)
      locations.forEach(({ key }) => {
        remove(locations, { key });
      });
      setLocations([]);
    }

  }, [ubicaciones])

  useEffect(() => {
    const onChangeType = (index, value) => {
      let dataTmp = [...data];
      dataTmp[index].description_filter =
        value === 'estado_home'
          ? 'estado_home'
          : value === 'estado_work'
          ? 'estado_work'
          : 'estado_all';
      setData(dataTmp)
    }
    
    if (data) setColumns([
      {
        title: 'Name',
        dataIndex: 'value_filter',
        width: '250px',
      },
      {
        title: 'Description',
        dataIndex: 'description_filter',
        render: (text, record, index) => {
          return (
            <Row className="SubmenuRegularGA UbicacionesFilters">
              <Col span={24}>
                <Radio.Group
                  options={[{ label: 'Domicilio', value: 'estado_home' }, { label: 'Trabajo', value: 'estado_work' }, , { label: 'Ambos', value: 'estado_all' }]}
                  value={record.description_filter}
                  onChange={({ target }) => onChangeType(index, target.value)}
                />
              </Col>
            </Row>
          )
        }
      },
    ])
  }, [data])

  const onSelectTable = (record, selected) => {
    if (!selected) {
      remove(locations, { key: record.key })
      setLocations([...locations])
    } else {
      setLocations([...locations, record])
    }
  }

  return (
    <Row className="Ubicaciones">
      <Col span={16}>
        <Space direction="vertical">
          <Search
            placeholder="Buscar"
            allowClear
            loading={showLoadingData}
            onSearch={(value) => {
              setShowLoadingData(true)
              setSearchValue(value)
            }}
          />
        </Space>
        {columns ?
          <Table
            className="tableComportamientos"
            rowSelection={{
              selectedRowKeys,
              onSelect: onSelectTable
            }}
            columns={columns}
            loading={showLoadingData}
            dataSource={data}
            pagination={false}
            rowKey='key'
            scroll={{ y: 290, }}
          /> :
          'Sin resultados...'
        }
      </Col>
      <Col className="ResultSearch" span={8}>
        {
          locations && locations.map(({ key, value_filter, description_filter }) => (
          <Tag
            key={key}
            className="TagsSelectFilters"
            closable
            onClose={(e) => {
              remove(locations, { key: key })
              setLocations([...locations])
              setBanderaTags(true)
            }}
          >
            {description_filter === 'estado_home'
              ? 'Domicilio: '
              : description_filter === 'estado_work'
              ? 'Trabajo: '
              : 'Domicilio y Trabajo: '}
            {value_filter}
          </Tag>
          )
          )
        }
      </Col>
    </Row>
  )
}
export default Ubicaciones;
