import './FiltersTable.css'
import { Table } from 'antd';
import { remove } from 'lodash'
import React, { useState, useEffect } from 'react';

const FiltersTable = (
  {
    selectedData,
    setSelectedData,
    selectedsData,
    setSelectedsData,
    filterComponent,
    setFilterComponent,
    banderaTags,
    setBanderaTags,
    showLoadingData,
    setShowLoadingData,
  }
) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [columns, setColumns] = useState();

  useEffect(() => {
    setShowLoadingData(true)
    if (selectedsData.length > 0) {
      setSelectedRowKeys(selectedsData.map(item => item.value_filter))
      setFilterComponent(selectedsData)
    }
    //Por bug cuando hay un solo tag
    if (selectedsData.length == 0) {
      setSelectedRowKeys([])
      if (banderaTags) {
        setBanderaTags(false)
        setFilterComponent([])
      }
    }
  }, [selectedsData])

  useEffect(() => {
    if (selectedData) {
      setSelectedRowKeys(filterComponent.map(item => selectedData.map(e => e.value_filter).includes(item.value_filter) ? item.value_filter : null))
      setSelectedsData(filterComponent)
      setShowLoadingData(false)
    }
  }, [filterComponent, selectedData, showLoadingData])
  useEffect(() => {
    if (selectedData) {
      setColumns([
        {
          title: 'Name',
          dataIndex: 'value_filter',
        },
      ])
    }
  }, [selectedData])
  const onSelectTable = (record, selected) => {
    if (!selected) {
      remove(selectedsData, { key: record.value_filter })
      setSelectedsData([...selectedsData])
    } else {
      setSelectedsData([...selectedsData, record])
    }
  }
  return (
    <>
      {columns ?
      <Table
        className="tableComportamientos"
        rowSelection={{
          selectedRowKeys,
          onSelect: onSelectTable
        }}
        columns={columns}
        loading={showLoadingData}
        dataSource={selectedData}
        pagination={{ pageSize: 1000}}
        rowKey='value_filter'
        scroll={{ y: 200 }}
      />
          : 
          'Sin resultados...'
      }
    </>
  )
}
export default FiltersTable;
