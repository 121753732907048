import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Space, Tag } from 'antd';
import { remove } from 'lodash'
import { API } from 'aws-amplify';
import FiltersTable from '../../FiltersTable';
import * as custom_queries from "../../../../Custom_queries/queries";
import './Dispositivos.css'

const { Search } = Input;

const Dispositivos = (
  {
    marca,
    setMarca,
    tipo,
    setTipo,
    sistemaOperativo,
    setSistemaOperativo,
  }
) => {
  const [selectedData1, setSelectedData1] = useState();
  const [selectedData2, setSelectedData2] = useState();
  const [selectedData3, setSelectedData3] = useState();
  const [searchValue1, setSearchValue1] = useState();
  const [searchValue2, setSearchValue2] = useState();
  const [searchValue3, setSearchValue3] = useState();
  const [showLoadingData1, setShowLoadingData1] = useState(false);
  const [showLoadingData2, setShowLoadingData2] = useState(false);
  const [showLoadingData3, setShowLoadingData3] = useState(false);
  const [selectedsData1, setSelectedsData1] = useState([]);
  const [selectedsData2, setSelectedsData2] = useState([]);
  const [selectedsData3, setSelectedsData3] = useState([]);
  const [banderaTags1, setBanderaTags1] = useState(false)
  const [banderaTags2, setBanderaTags2] = useState(false)
  const [banderaTags3, setBanderaTags3] = useState(false)

  const fetchFilter1 = async (filter = null) => {
    try {
      const response = await API.graphql({
        query: `query MyQuery {
          getFiltersRedshift(input: { query: "getBranchDevices", name_filter: "${filter}" }) {
            key
            description_filter
            type_filter
            value_filter
            id_statement
          }
        }`
      });
  
      const data = response.data.getFiltersRedshift.sort((a, b) => a.value_filter.localeCompare(b.value_filter));
  
      if (data[0].id_statement !== null) {
        await getValues({
          idStatement: data[0].id_statement,
          query: "getBranchDevices",
          setter: setSelectedData1,
          showLoading: setShowLoadingData1
        });
      } else {
        const newData = data.map(item => {
          const { id_statement, ...rest } = item;
          return rest;
        });
  
        setSelectedData1(newData.length > 0 && newData[0].value_filter !== "Sin Datos" ? newData : []);
        setShowLoadingData1(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };
  
  useEffect(() => {
    setShowLoadingData1(true)
    fetchFilter1(searchValue1)
  }, [searchValue1])

  const fetchFilter2 = async (filter = null) => {
    try {
      const response = await API.graphql({
        query: `query MyQuery {
          getFiltersRedshift(input: { query: "getTypeDevices", name_filter: "${filter}" }) {
            key
            description_filter
            type_filter
            value_filter
            id_statement
          }
        }`
      });
  
      const data = response.data.getFiltersRedshift.sort((a, b) => a.value_filter.localeCompare(b.value_filter));;
  
      if (data[0].id_statement !== null) {
        await getValues({
          idStatement: data[0].id_statement,
          query: "getTypeDevices",
          setter: setSelectedData2,
          showLoading: setShowLoadingData2
        });
      } else {
        const newData = data.map(item => {
          const { id_statement, ...rest } = item;
          return rest;
        });
  
        setSelectedData2(newData.length > 0 && newData[0].value_filter !== "Sin Datos" ? newData : []);
        setShowLoadingData2(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };
  
  useEffect(() => {
    setShowLoadingData2(true)
    fetchFilter2(searchValue2)
  }, [searchValue2])

  const fetchFilter3 = async (filter = null) => {
    try {
      const response = await API.graphql({
        query: `query MyQuery {
          getFiltersRedshift(input: { query: "getOsDevices", name_filter: "${filter}" }) {
            key
            description_filter
            type_filter
            value_filter
            id_statement
          }
        }`
      });
  
      const data = response.data.getFiltersRedshift.sort((a, b) => a.value_filter.localeCompare(b.value_filter));;
  
      if (data[0].id_statement !== null) {
        await getValues({
          idStatement: data[0].id_statement,
          query: "getOsDevices",
          setter: setSelectedData3,
          showLoading: setShowLoadingData3
        });
      } else {
        const newData = data.map(item => {
          const { id_statement, ...rest } = item;
          return rest;
        });
  
        setSelectedData3(newData.length > 0 && newData[0].value_filter !== "Sin Datos" ? newData : []);
        setShowLoadingData3(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };
  
  useEffect(() => {
    setShowLoadingData3(true)
    fetchFilter3(searchValue3)
  }, [searchValue3])

  const getValues = async ({ idStatement, query, setter, showLoading }) => {
    let response = [];
    while (response.length === 0) {
      const result = await API.graphql({
        query: `query MyQuery {
          getData(input: {id_statement: "${idStatement}", query: "${query}" }) {
            key
            description_filter
            type_filter
            value_filter
          }
        }`
      });
      response = result.data.getData;

      if (response.length === 0) {
        await new Promise(resolve => setTimeout(resolve, 5000)); // Esperar 5 segundos antes de hacer la siguiente petición
      }
    }
    setter(response.length > 0 && response[0].value_filter !== "Sin Datos" ? response : [])
    showLoading(false)
  };

  return (
    <Row className="Ubicaciones Dispositivos">
      <Col span={6}>
        <h3>Marca</h3>
        <Space direction="vertical">
          <Search
            placeholder="Buscar"
            allowClear
            loading={showLoadingData1}
            onSearch={(value) => {
              setShowLoadingData1(true)
              setSearchValue1(value)
            }}
          />
        </Space>
        <FiltersTable
          selectedData={selectedData1}
          setSelectedData={setSelectedData1}
          selectedsData={selectedsData1}
          setSelectedsData={setSelectedsData1}
          filterComponent={marca}
          setFilterComponent={setMarca}
          banderaTags={banderaTags1}
          setBanderaTags={setBanderaTags1}
          showLoadingData={showLoadingData1}
          setShowLoadingData={setShowLoadingData1}
        />
      </Col>
      <Col span={6}>
        <h3>Tipo</h3>
        <Space direction="vertical">
          <Search
            placeholder="Buscar"
            allowClear
            loading={showLoadingData2}
            onSearch={(value) => {
              setShowLoadingData2(true)
              setSearchValue2(value)
            }}
          />
        </Space>
        <FiltersTable
          selectedData={selectedData2}
          setSelectedData={setSelectedData2}
          selectedsData={selectedsData2}
          setSelectedsData={setSelectedsData2}
          filterComponent={tipo}
          setFilterComponent={setTipo}
          banderaTags={banderaTags2}
          setBanderaTags={setBanderaTags2}
          showLoadingData={showLoadingData2}
          setShowLoadingData={setShowLoadingData2}
        />
      </Col>
      <Col span={6}>
        <h3>Sistema Operativo</h3>
        <Space direction="vertical">
          <Search
            placeholder="Buscar"
            allowClear
            loading={showLoadingData3}
            onSearch={(value) => {
              setShowLoadingData3(true)
              setSearchValue3(value)
            }}
          />
        </Space>
        <FiltersTable
          selectedData={selectedData3}
          setSelectedData={setSelectedData3}
          selectedsData={selectedsData3}
          setSelectedsData={setSelectedsData3}
          filterComponent={sistemaOperativo}
          setFilterComponent={setSistemaOperativo}
          banderaTags={banderaTags3}
          setBanderaTags={setBanderaTags3}
          showLoadingData={showLoadingData3}
          setShowLoadingData={setShowLoadingData3}
        />
      </Col>
      <Col className="ResultSearch" span={6}>
        {
          selectedsData1 && selectedsData1.map(({ key, value_filter, type }) => (
            <Tag
              key={key}
              className="TagsSelectFilters"
              closable
              onClose={e => {
                remove(selectedsData1, { key: key })
                setSelectedsData1([...selectedsData1])
                setBanderaTags1(true)
              }}
            >
              {value_filter}
            </Tag>
          )
          )
        }
        {
          selectedsData2 && selectedsData2.map(({ key, value_filter, type }) => (
            <Tag
              key={key}
              className="TagsSelectFilters"
              closable
              onClose={e => {
                remove(selectedsData2, { key: key })
                setSelectedsData2([...selectedsData2])
                setBanderaTags2(true)
              }}
            >
              {value_filter}
            </Tag>
          )
          )
        }
        {
          selectedsData3 && selectedsData3.map(({ key, value_filter, type }) => (
            <Tag
              key={key}
              className="TagsSelectFilters"
              closable
              onClose={e => {
                remove(selectedsData3, { key: key })
                setSelectedsData3([...selectedsData3])
                setBanderaTags3(true)
              }}
            >
              {value_filter}
            </Tag>
          )
          )
        }
      </Col>
    </Row>
  )
}
export default Dispositivos;
