import { useState, useEffect, useCallback } from "react";
import { Typography, Avatar, Popover, Spin } from "antd";
import { API } from "aws-amplify";
import * as queries from "../../../graphql/queries";
const { Title, Text } = Typography;

export function TopValue({ request }) {
  const [dataname, setDataName] = useState();
  const [datavalue, setDataValue] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await API.graphql({
        query: `
          query get($input: getFiltersRedshiftInput){
            getFiltersRedshift(input: $input) {
              key
              type_filter
              description_filter
              value_filter
              is_app
              subscribers
            }
          }
        `,
        variables: {
          input: { query: request },
          key: 1,
        },
      });
      //console.log(response.data)
      if (response.data && response.data.getFiltersRedshift.length > 0) {
        const filteredData = response.data.getFiltersRedshift.filter(row => row.value_filter !== "Desconocido" && row.value_filter !== "DESCONOCIDO");
        if (filteredData.length > 0) {
          const sortedData = filteredData.sort((a, b) => b.subscribers - a.subscribers);
          const maxValue = sortedData[0].subscribers > sortedData[1].subscribers ? sortedData[0] : sortedData[1];
          setDataName(maxValue.value_filter);
          setDataValue(maxValue.subscribers.toLocaleString("en-US", { notation: "compact", compactDisplay: "short" }));
        } else {
          setDataName("Desconocido");
          setDataValue("-");
        }
      }else {
        setDataName("Sin datos");
        setDataValue("-");
      }
      
    };
    fetchData();
  }, [request]);
  

  return (
    <>
      <Title className="CifraGeneralResaltadaGA" level={3}>
        {datavalue}
      </Title>
      <Text className="SubmenuRegularGA">{dataname}</Text>
    </>
  );
}
export function TopValueBE({ request, dataBaseExternal }) {
  const [dataname, setDataName] = useState();
  const [datavalue, setDataValue] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await API.graphql({
        query: `
  query get($input: getFiltersRedshiftInput){
  getFiltersRedshift(input: $input) {
    key
    type_filter
    description_filter
    value_filter
    is_app
    subscribers
  }
  } `,
        variables: {
          input: { 
            query: request, 
            idClm: dataBaseExternal,
          },
          key: 1,
        },
      });

      setDataName(response.data.getFiltersRedshift["0"].value_filter);
      setDataValue(
        response.data.getFiltersRedshift["0"].subscribers.toLocaleString(
          "en-US",
          { notation: "compact", compactDisplay: "short" }
        )
      );
    };
    fetchData();
  }, [ request]);

  return (
    <>
      <Title className="CifraGeneralResaltadaGA topCifraBE" level={3} color={"#00C6A2"}>
        {datavalue}
      </Title>
      <Text className="SubmenuRegularGA">{dataname}</Text>
    </>
  );
}

export function TotalAudience() {
  const [dataaudience, setDataAudience] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await API.graphql({
        query: `
query get($input: getFiltersRedshiftInput){
getFiltersRedshift(input: $input) {
  key
  type_filter
  description_filter
  value_filter
  is_app
  subscribers
}
} `,
        variables: {
          input: { query: "getAllAudiences" },
        },
      });
      setDataAudience(
        response.data.getFiltersRedshift["0"].subscribers.toLocaleString(
          "en-US",
          { notation: "compact", compactDisplay: "short" }
        )
      );
    };
    fetchData();
  }, []);

  return (
    <>
      <Title className="CifraGeneralResaltadaGA" level={3}>
        {dataaudience}
      </Title>
      <Text className="SubmenuRegularGA">Audiencia</Text>
    </>
  );
}
export function TotalAudienceBE({dataBaseExternal}) {
  const [dataaudience, setDataAudience] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const response = await API.graphql({
        query: `
query get($input: getFiltersRedshiftInput){
getFiltersRedshift(input: $input) {
  key
  type_filter
  description_filter
  value_filter
  is_app
  subscribers
}
} `,
        variables: {
          input: { 
            query: "getAllAudiences",
            idClm: dataBaseExternal,
          },
        },
      });
      setDataAudience(
        response.data.getFiltersRedshift["0"].subscribers.toLocaleString(
          "en-US",
          { notation: "compact", compactDisplay: "short" }
        )
      );
    };
    fetchData();
    setLoading(false)
  }, []);

  return (
    <>
      <Title className="CifraGeneralResaltadaGA topCifraBE" level={3}>
          <Spin spinning={loading}>
        {dataaudience}
        </Spin>
      </Title>
      <Text className="SubmenuRegularGA">Base Externa</Text>
    </>
  );
}

export function TotalFilter({ valor, filter, color, text, loadingTotal }) {

  return (
    <>
      <Avatar size={74} style={{ backgroundColor: color }}>
        <Text strong style={{ color: "#FFFFFF" }}>
        <Spin spinning={loadingTotal || valor === null}>
          {valor ? valor.toLocaleString("en-US", { notation: "compact", compactDisplay: "short" }) : valor}
        </Spin>
        </Text>
      </Avatar>
      <br />
      <Text>{text}</Text>
    </>
  );
}

export function TotalTwoFilters({totalbdaudience, totalnewaudience}) {

  return (
    <>
    <Popover content="Audiencia total en BD externa">
      <Avatar size={55} style={{ backgroundColor: "#00C6A2" }}>
        <Text strong style={{ color: "#FFFFFF" }}>
          { totalbdaudience ? totalbdaudience.toLocaleString( "en-US", { notation: "compact", compactDisplay: "short" }) : null}
        </Text>
      </Avatar>
      </Popover>
      -
      <Popover content="Audiencia total en Nueva audiencia">
      <Avatar size={55} style={{ backgroundColor: "#FF596F" }}>
        <Text strong style={{ color: "#FFFFFF" }}>
          { totalnewaudience ? totalnewaudience.toLocaleString( "en-US", { notation: "compact", compactDisplay: "short" }) : null}
        </Text>
      </Avatar>
      </Popover>
    </>
  );
}
