import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Space, Tag } from 'antd';
import { remove } from 'lodash'
import { API } from 'aws-amplify';
import FiltersTable from '../../FiltersTable';
import * as custom_queries from "../../../../Custom_queries/queries";
import './Demograficos.css'

const { Search } = Input;

const Demograficos = (
  {
    genero,
    setGenero,
    ingreso,
    setIngreso,
    edad,
    setEdad,
  }
) => {
  const [selectedData1, setSelectedData1] = useState();
  const [selectedData2, setSelectedData2] = useState();
  const [selectedData3, setSelectedData3] = useState();
  const [searchValue1, setSearchValue1] = useState();
  const [searchValue2, setSearchValue2] = useState();
  const [searchValue3, setSearchValue3] = useState();
  const [showLoadingData1, setShowLoadingData1] = useState(false);
  const [showLoadingData2, setShowLoadingData2] = useState(false);
  const [showLoadingData3, setShowLoadingData3] = useState(false);
  const [selectedsData1, setSelectedsData1] = useState([]);
  const [selectedsData2, setSelectedsData2] = useState([]);
  const [selectedsData3, setSelectedsData3] = useState([]);
  const [banderaTags1, setBanderaTags1] = useState(false)
  const [banderaTags2, setBanderaTags2] = useState(false)
  const [banderaTags3, setBanderaTags3] = useState(false)


  const fetchFilter1 = async (filter = null) => {
    try {
      const response = await API.graphql({
        query: `query MyQuery {
          getFiltersRedshift(input: { query: "getGender", name_filter: "${filter}" }) {
            key
            description_filter
            type_filter
            value_filter
          }
        }`
      });
  
      const data = response.data.getFiltersRedshift;
  
      setSelectedData1(data.length > 0 && data[0].value_filter !== "Sin Datos" ? data.sort((a, b) => a.value_filter.localeCompare(b.value_filter)) : []);
      setShowLoadingData1(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };
  
  useEffect(() => {
    setShowLoadingData1(true)
    fetchFilter1(searchValue1)
  }, [])

  const fetchFilter2 = async (filter = null) => {
    try {
      const response = await API.graphql({
        query: `query MyQuery {
          getFiltersRedshift(input: { query: "getIngresos", name_filter: "${filter}" }) {
            key
            description_filter
            type_filter
            value_filter
          }
        }`
      });
  
      const data = response.data.getFiltersRedshift;
  
      setSelectedData2(data.length > 0 && data[0].value_filter !== "Sin Datos" ? data.sort((a, b) => a.value_filter.localeCompare(b.value_filter)) : []);
      setShowLoadingData2(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };
  
  useEffect(() => {
    setShowLoadingData2(true)
    fetchFilter2(searchValue2)
  }, [])

  const fetchFilter3 = async (filter = null) => {
    try {
      const response = await API.graphql({
        query: `query MyQuery {
          getFiltersRedshift(input: { query: "getAges", name_filter: "${filter}" }) {
            key
            description_filter
            type_filter
            value_filter
          }
        }`
      });
  
      const data = response.data.getFiltersRedshift;
  
      setSelectedData3(data.length > 0 && data[0].value_filter !== "Sin Datos" ? data.sort((a, b) => a.value_filter.localeCompare(b.value_filter)) : []);
      setShowLoadingData3(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };
  
  useEffect(() => {
    setShowLoadingData3(true)
    fetchFilter3(searchValue3)
  }, [])

  return (
    <Row className="DemograficosColumns">
      <Col span={6}>
        <h3>Genero</h3>
        <FiltersTable
          selectedData={selectedData1}
          setSelectedData={setSelectedData1}
          selectedsData={selectedsData1}
          setSelectedsData={setSelectedsData1}
          filterComponent={genero}
          setFilterComponent={setGenero}
          banderaTags={banderaTags1}
          setBanderaTags={setBanderaTags1}
          showLoadingData={showLoadingData1}
          setShowLoadingData={setShowLoadingData1}
        />
      </Col>
      <Col span={6}>
        <h3>Ingreso</h3>
        <FiltersTable
          selectedData={selectedData2}
          setSelectedData={setSelectedData2}
          selectedsData={selectedsData2}
          setSelectedsData={setSelectedsData2}
          filterComponent={ingreso}
          setFilterComponent={setIngreso}
          banderaTags={banderaTags2}
          setBanderaTags={setBanderaTags2}
          showLoadingData={showLoadingData2}
          setShowLoadingData={setShowLoadingData2}
        />
      </Col>
      <Col span={6}>
        <h3>Edad</h3>
        <FiltersTable
          selectedData={selectedData3}
          setSelectedData={setSelectedData3}
          selectedsData={selectedsData3}
          setSelectedsData={setSelectedsData3}
          filterComponent={edad}
          setFilterComponent={setEdad}
          banderaTags={banderaTags3}
          setBanderaTags={setBanderaTags3}
          showLoadingData={showLoadingData3}
          setShowLoadingData={setShowLoadingData3}
        />
      </Col>
      <Col className="ResultSearch" span={6}>
        {
          selectedsData1 && selectedsData1.map(({ key, value_filter, type }) => (
            <Tag
              key={key}
              className="TagsSelectFilters"
              closable
              onClose={e => {
                remove(selectedsData1, { key: key })
                setSelectedsData1([...selectedsData1])
                setBanderaTags1(true)
              }}
            >
              {value_filter}
            </Tag>
          )
          )
        }
        {
          selectedsData2 && selectedsData2.map(({ key, value_filter, type }) => (
            <Tag
              key={key}
              className="TagsSelectFilters"
              closable
              onClose={e => {
                remove(selectedsData2, { key: key })
                setSelectedsData2([...selectedsData2])
                setBanderaTags2(true)
              }}
            >
              {value_filter}
            </Tag>
          )
          )
        }
        {
          selectedsData3 && selectedsData3.map(({ key, value_filter, type }) => (
            <Tag
              key={key}
              className="TagsSelectFilters"
              closable
              onClose={e => {
                remove(selectedsData3, { key: key })
                setSelectedsData3([...selectedsData3])
                setBanderaTags3(true)
              }}
            >
              {value_filter}
            </Tag>
          )
          )
        }
      </Col>
    </Row>
  )
}
export default Demograficos;
